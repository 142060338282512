<template>
  <div>
    <div
      class=""
      :class="{ 'dark-mode': isDark, 'light-mode': !isDark }"
    >
      <v-card class="ml-10 mr-10 px-5 py-5 d-flex justify-content-end">
        <v-col
          cols="12"
          class="d-flex justify-content-end"
        >
          <div class="container-profile">
            <v-icon
              class="me-6"
              @click="$router.push({ path: '/' })"
            >
              {{ icons.mdiHome }}
            </v-icon>
            <app-bar-theme-switcher></app-bar-theme-switcher>
            <app-bar-user-menu></app-bar-user-menu>
          </div>
        </v-col>
      </v-card>
      <v-card
        id="account-setting-card"
        class="ml-10 mr-10 mt-10"
      >
        <v-tabs
          v-model="tabs"
          show-arrows
        >
          <v-tab
            v-for="tab in tabItems"
            :key="tab.title"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tabs">
          <v-tab-item
            v-for="tab in tabItems"
            :key="tab.title"
          >
            <component :is="tab.component"></component>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>
  </div>
</template>

<script>
import AppBarUserMenu from '@/components/AppBarUserMenu.vue'
import AppBarThemeSwitcher from '@core/layouts/components/app-bar/AppBarThemeSwitcher.vue'
import { getVuetify } from '@core/utils'
import {
  mdiAccountMultiple, mdiBadgeAccount, mdiFormSelect,
  mdiHome,
  mdiNote, mdiPaperclip, mdiServerNetwork,
} from '@mdi/js'
import AnexType from './Types/AnexType.vue'
import AtrType from './Types/AtrType.vue'
import FlxType from './Types/FlxType.vue'
import FormType from './Types/FormType.vue'
import MqType from './Types/MqType.vue'
import PPType from './Types/PPType.vue'
import PopType from './Types/PopType.vue'

export default {
  components: {
    AtrType,
    PopType,
    FormType,
    MqType,
    PPType,
    AnexType,
    FlxType,
    AppBarUserMenu,
    AppBarThemeSwitcher,
  },
  data() {
    return {
      icons: {
        mdiHome,
      },
      tabs: '',
      tabItems: [
        { title: 'ATR', icon: mdiAccountMultiple, component: AtrType },
        { title: 'POP', icon: mdiNote, component: PopType },
        { title: 'FORM', icon: mdiFormSelect, component: FormType },
        { title: 'MQ', icon: mdiFormSelect, component: MqType },
        { title: 'PP', icon: mdiBadgeAccount, component: PPType },
        { title: 'ANEX', icon: mdiPaperclip, component: AnexType },
        { title: 'FLX', icon: mdiServerNetwork, component: FlxType },
      ],

      skeleton: false,
    }
  },

  computed: {
    isDark() {
      const $vuetify = getVuetify()

      return $vuetify.theme.dark
    },
  },
}
</script>
<style scoped>
.container-profile{
  width: 100%;
  display: flex;
  justify-content: flex-end;
}</style>
